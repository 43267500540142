import { Modal } from 'components/Common/Modal';
import React from 'react';
import { useStockContext } from '../useStockContext';
import { DelayedDelivery } from './DelayedDelivery';

export function StockMessageModal({ activeVariant }) {
	const {
		isNextDay,
		deliveryMessage,
		estimatedDelivery,
		modalOpen,
		setModalOpen,
	} = useStockContext();

	return (
		<Modal
			modalOpen={modalOpen}
			setModalOpen={setModalOpen}
			title={deliveryMessage}
		>
			<div className="u-px--small u-px--large--bp-medium u-py--xsmall">
				{activeVariant.stock === 'pre' && (
					<p className="u-fz--title-small u-mb--small">
						<strong>Pre-Order</strong> - Estimated delivery:{' '}
						{estimatedDelivery}
					</p>
				)}
				<p className="u-fz--title-small">
					{activeVariant.stock === 'none' && !isNextDay ? (
						<>
							We don&apos;t have the stock to deliver this right
							now, but we will soon. If you order today, DPD
							should have it with you by {estimatedDelivery}.* And
							delivery is completely free!
							<br />
							<br />
							*Subject to your postcode, stock availability and
							same day credit/security check approval.
							<br />
							<br />
						</>
					) : (
						<span className="stock">
							If you place your order before 8pm Monday-Thursday,
							before 7pm Friday or 4pm Saturday-Sunday, DPD will
							have it at your doorstep the very next day.
							<br />
							<br />
							*Excludes bank holidays, and is subject to your
							postcode, stock availability, and same day
							credit/security check approval.
						</span>
					)}
				</p>
				{activeVariant.stock === 'none' &&
					!isNextDay &&
					!window.location.href.includes('/sim-free') && (
						<DelayedDelivery />
					)}
			</div>
		</Modal>
	);
}
